div.projects-container {
  @media only screen and (max-width: 425px) {
    margin: 30px 30px 0px 30px;
  }
  margin: 80px 100px 0px 100px;

  div.upper-container {
    display: flex;
    flex-direction: column;

    div.first-row {
      h2 {
        color: $beige;
        font-size: 32px;
        font-family: "Outfit-light";
        color: $beige;
        text-transform: uppercase;

        @media only screen and (max-width: 425px) {
          font-size: 18px;
        }

      }
    }

    div.second-row {
      @media only screen and (max-width: 425px) {
        flex-direction: column;;
      }

      display: flex;
      width: 100%;

      h1 {
        color: white;
        font-size: 48px;
        font-family: "Outfit-Light";
        width: 40%;
        margin-top: 20px;

        @media only screen and (max-width: 425px) {
          font-size: 28px;
          width: 100%;
        }

      }

      p.text {
        @media only screen and (max-width: 425px) {
          margin-left: 0px;
          width: 100%;
        }

        color: white;
        font-size: 20px;
        line-height: 40px;
        font-family: "Outfit-Light";
        width: 60%;
        margin-top: 20px;
        margin-left: 40px;
      }
    }
  }

  div.our-projects {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
    flex-wrap: wrap;
    
    @media only screen and (max-width: 425px) {
      flex-direction: column;
      font-size: 28px;
      width: 100%;
      height: 100%;
      padding-bottom: 0px;
    }

    div.vr-tour {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      align-items: flex-start;
      margin-top: 50px;
      
      @media only screen and (max-width: 425px) {
        margin-bottom: 30px;
        width: 100%;
      }

      a {
        img {
          width: 25vw;
          @media only screen and (max-width: 425px) {
            width: 100%;
          }
        }
        img:hover {
          box-shadow: 0px 0px 4px rgb(255 255 255 / 30%);
        }
      }

      h3 {
        color: white;
        font-family: "Outfit-ExtraLight";
        margin-bottom: 10px;
      }

      h4 {
        color: grey;
        font-family: "Outfit-ExtraLight";
        margin: 0px;
      }
    }
  }

  div.projects-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

}

div.about-container {
  background-color: $white;
  width: calc(100% - 100px);
  padding-top: 100px;
  padding-left: 100px;

  @media only screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
  }
  
  h2 {
    @media only screen and (max-width: 425px) {
      margin-top: 50px;
      margin-left: 30px;
      font-size: 18px;
    }

    font-size: 32px;
    font-family: "Outfit-light";
    color: $beige;
    text-transform: uppercase;
  }

  div.about-container-wrapper {
    @media only screen and (max-width: 425px) {
      display: flex;
      flex-direction: column-reverse;
    }
    
    display: flex;
    width: 100%;
    

    div.right-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 50%;
      @media only screen and (max-width: 425px) {
        width: 100%;
      }


      img {
        max-width: 600px;
        object-fit: contain;
        
        @media only screen and (max-width: 425px) {
          width: calc(100% - 40px);
          height: 300px;
          object-fit: cover;
          margin-top: 30px;
        }
      }
    }

    div.left-container {
      @media only screen and (max-width: 425px) {
        width: 100%;
        padding: 0px;
      }

      width: 50%;
      padding-right: 30px;
      position: relative;
      bottom: 12px;

        h1 {
          font-size: 48px;
          font-family: "Outfit-Light";
          margin-top: 0;
          
          @media only screen and (max-width: 425px) {
            font-size: 30px;;
            margin: 30px 30px 0px 30px;
        }
        
      }
      
      p {
        @media only screen and (max-width: 425px) {
          width: calc(100% - 60px);
          font-size: 16px;
          margin: 0px 30px;
        }
        font-size: 20px;
        line-height: 40px;
      }

      ul {

        @media only screen and (max-width: 425px) {
          flex-direction: column;
          align-items: flex-start;
        }

        display: flex;
        width: 100%;
        margin: 0;
        padding: 0;

        li {

          @media only screen and (max-width: 425px) {
            height: 45px;
            margin-left: 30px;
          }

          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
          font-size: 20px;

          p {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

div.frontpage-container {
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 50px;

  @media only screen and (max-width: 425px) {
    margin-top: 0px;
    padding-top: 90px;
  }

  div.left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    height: 100%;
    position: relative;

    @media only screen and (max-width: 425px) {
      width: 100%;
    }

    img.logo-text {
      position: absolute;
      z-index: 1;
      @media only screen and (max-width: 425px) {
        display: none;
      }
    }

    img.bg-image {
      @media only screen and (max-width: 425px) {
        width: 100%;
        object-fit: contain;
        object-position: 0 30%;
      }
      
      position: absolute;
      opacity: 0.25;
      width: 100%;
      max-width: 800px;
    }

    h1 {
      font-size: 60px;
      color: $beige;
      width: 70%;
      font-family: "Outfit-Light";
      margin-left: 100px;
      margin-top: 0;
      z-index: 1;
      
      @media only screen and (max-width: 425px) {
        margin-left: 30px;
        font-size: 38px;
        width: 80%;
        margin-top: 50px;
      }
    }

    p {
      color: white;
      width: 70%;
      line-height: 40px;
      margin-bottom: 200px;
      margin-left: 100px;
      z-index: 1;
      
      
      @media only screen and (max-width: 425px) {
        margin-left: 30px;
        width: 85%;
        margin-bottom: 0px;
    }

    }
    div.contact-email-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 20px;

      div.left-contact-email-container {
        img {
          height: 50px;
        }
      }

      div.right-contact-email-container {
        margin-left: 20px;
        p {
          margin: 0;
          padding: 0;
          color: white;
          font-size: 16px;
          height: 30px;
        }
  
        a {
          color: white;
          text-decoration: none;
          font-weight: 600;
          height: 30px;
        }
      }
    }
  }
  div.right-container {
    width: 50%;
    
    @media only screen and (max-width: 425px) {
      display: none;
    }
  }
}

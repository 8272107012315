div.services-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: $mediumgrey;
  padding: 50px;

  @media only screen and (max-width: 425px) {
    padding: 20px 0px;
  }

  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

div.navigation-container {
  height: 90px;
  width: 100%;
  border-bottom: 1px solid $dimgrey;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 425px) {
    position: fixed;
    z-index: 1001;
    background-color: #313131;
  }

  div.left-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 425px) {
      width: 50%;
    }

    img.logo-text {
      display: none;
      @media only screen and (max-width: 425px) {
        display: block;
        height: 65px;
        margin-left: 75px;
      }
    }
  }

  div.center-container {
    @media only screen and (max-width: 425px) {
      display: none;
    }

    width: 55%;

    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 100%;
        color: $white;
        cursor: pointer;
        text-transform: uppercase;
        padding: 0px 20px;

        &:hover {
          color: $beige;
        }

        &.active {
          color: $beige;
        }
      }
    }
  }

  div.right-container {
    @media only screen and (max-width: 425px) {
      width: calc(50% - 40px);
      justify-content: flex-end;
      margin: 0px 20px;
    }


    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: $white;

    div.phone-container {
      @media only screen and (max-width: 425px) {
        display: none;
      }

      a {
        color: white;
        text-decoration: none;
      }
    }


    div.menu-container {
      width: 70px;
      height: 70px;

        img {
          height: 100%;
          object-fit: cover;

        }
    }
  }
}

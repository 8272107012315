@import "./styles/utils/_colors";

//COMPONENTS
@import "./styles/components/Navigation.scss";
@import "./styles/components/Service.scss";

//PAGES
@import "./styles/pages/FrontPage.scss";
@import "./styles/pages/Services.scss";
@import "./styles/pages/About.scss";
@import "./styles/pages/Projects.scss";
@import "./styles/pages/Vision.scss";
@import "./styles/pages/Proces.scss";
@import "./styles/pages/Contact.scss";
@import "./styles/pages/Footer.scss";

@font-face {
  font-family: "Outfit-Black";
  src: local(""), url("../src/assets/fonts/Outfit-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Bold";
  src: local(""), url("../src/assets/fonts/Outfit-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-ExtraBold";
  src: local(""), url("../src/assets/fonts/Outfit-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-ExtraLight";
  src: local(""), url("../src/assets/fonts/Outfit-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Light";
  src: local(""), url("../src/assets/fonts/Outfit-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Medium";
  src: local(""), url("../src/assets/fonts/Outfit-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Regular";
  src: local(""), url("../src/assets/fonts/Outfit-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-SemiBold";
  src: local(""), url("../src/assets/fonts/Outfit-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Thin";
  src: local(""), url("../src/assets/fonts/Outfit-Thin.ttf") format("truetype");
}

div.App {
  background-color: $darkgrey;
}

li,
div {
  font-family: "Outfit-Regular";
  font-size: 16px;
}

.player {
  background-image: none !important;
}

p {
  font-size: 20px;
}

p.upload-button {
  font-size: 1em;

  &:hover {
    cursor: pointer;
  }
}

button.read-more-button {
  @media only screen and (max-width: 425px) {
    margin-left: 30px;
  }

  height: 50px;
  width: 150px;
  background-color: white;
  font-family: "Outfit-SemiBold";
  margin: 20px 0px;
  cursor: pointer;
  color: $beige;
  border-color: $beige;
  &:hover {
    cursor: pointer;
    color: $beige;
    border-color: $beige;
  }
}

div.footer-container {
  display: flex;
  flex-direction: column;
  background-color: $darkgrey;
  padding: 70px 50px 40px;

  @media only screen and (max-width: 425px) {
    padding: 70px 20px 40px;

  }

  div.footer-top-container {
    display: flex;
    width: 100%;
    justify-content: space-between;

    p.header {
      font-family: "Outfit-SemiBold";
    }

    p {
      margin: 10px;
    }

    div.social-media {
      display: flex;
      flex-direction: column;

      div.some-links {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;

        .logo-container {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid white;
          border-radius: 100px;
          padding: 10px;
          width: 15px;
          height: 15px;

          &:hover {
            cursor: pointer;
          }

          img {
            color: white;
            width: 15px;
            height: 15px;
          }
        }
      }

      img {
        width: 150px;
      }
    }

    div.links {
      display: flex;
      flex-direction: column;
      color: white;

      li {
        list-style-type: none;
        margin: 10px;
        text-transform: uppercase;

        cursor: pointer;

        &:hover {
          color: $beige;
        }
      }
    }

    div.contact {
      @media only screen and (max-width: 425px) {
        display: none;
      }

      display: flex;
      flex-direction: column;
      color: $beige;

      a {
        color: white;
        text-decoration: none;
        margin: 10px;

        span {
          font-size: 1.4em;
        }
      }
    }
  }

  div.line {
    margin: 20px 0px;
    width: 100%;
    border: 1px solid $mediumgrey;
  }

  div.footer-bottom-container {

    @media only screen and (max-width: 425px) {
      flex-direction: column;

      p {
        font-size: 15px;
        line-height: 30px;
        margin: 0;
      }
    }

    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
  }
}

div.proces-container {
  background-color: white;
  padding-top: 30px;
  padding-bottom: 50px;

  @media only screen and (max-width: 425px) {
    padding-top: 0px;
  }

  h1 {
    font-size: 32px;
    font-family: "Outfit-light";
    color: $beige;
    text-transform: uppercase;
    
    @media only screen and (max-width: 425px) {
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 20px;
      margin-left: 20px;
    }

  }
  div.outer-container {
    @media only screen and (max-width: 425px) {
      flex-direction: column-reverse;
    }

    display: flex;
    width: 100%;
    height: 100%;
 
    div.right-container {
      @media only screen and (max-width: 425px) {
        width: 100%;
        top: 0px;
      }

      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 50%;
      top: 133px;
      position: relative;

      img {
        height: 800px;

        @media only screen and (max-width: 425px) {
          width: calc(100% - 40px);
          height: 400px;
          object-fit: cover;
          margin-top: 30px;
        }
      }
    }

    div.left-container {
      @media only screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin: 0;
      }

      width: 45%;
      margin-left: 100px;

      div.steps-container {
        @media only screen and (max-width: 425px) {
          flex-direction: column;
          margin-bottom: 20px;
        }
        width: 100%;

        display: flex;
        margin-bottom: 50px;

        div.step {
          @media only screen and (max-width: 425px) {
            width: calc(100% - 40px);
            margin-left: 20px;

          }
          width: 200px;
          text-align: center;

          &:hover {
            color: $beige;
          }

          h3 {
            @media only screen and (max-width: 425px) {
              font-size: 12px;
              text-align: start;
            }
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 3px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      h2 {
        font-size: 25px;
        font-family: "Outfit-Light";

        @media only screen and (max-width: 425px) {
        margin-left: 20px;
        }


      }

      div.content-container {
        @media only screen and (max-width: 425px) {
          margin-left: 20px;
          }
          
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;

        div.step-data {
          width: 250px;
          margin: 0px 40px 0px 0px;

          @media only screen and (max-width: 425px) {
            width: 100%;
            }

          h4 {
            font-family: "Outfit-Semibold";
            font-size: 19px;
          }

          p {
            font-family: "Outfit-Light";
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }
  }
}

div.contact-container {
  background-color: #bfa47e;
  width: 100%;
  color: white;
  position: relative;
  padding: 100px 0px;
  
  @media only screen and (max-width: 425px) {
    padding: 60px 0px;
  }

  .text-container {
    @media only screen and (max-width: 425px) {
      width: 100%;
    }
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1,
    h2 {
      font-family: "Outfit-light";
      @media only screen and (max-width: 425px) {
        text-align: center;
        font-size: 22px;
        padding: 0px 20px;
        margin: 20px 0px 0px 0px;
      }
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 1.3em;
      font-family: "Outfit-light";

      span {
        font-size: 1.4em;
      }
    }
  }

  img {
    @media only screen and (max-width: 425px) {
      display: none;
    }
    height: 100%;
    position: absolute;
    top: 0;
    left: 40%;
  }
}

div.service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 50px;

  img {
    height: 50px;
  }

  h1 {
    color: white;
    font-size: 25px;
  }

  p {
    color: white;
    font-size: 18px;
    text-align: center;
    margin: 0;
  }
}

div.vision-container {
  display: flex;
  background-color: $mediumgrey;
  align-items: center;
  min-height: 800px;
  width: calc(100% - 200px);
  padding: 0px 100px 60px 100px;

  
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 50px 0px;
    width: 100%;
    min-height: 0px;
  }

  div.left-container {
    display: flex;
    justify-content: center;
    width: 50%;
    max-height: 1000px;

    @media only screen and (max-width: 425px) {
      width: 100%;
      height: 100%;
    }
    

    img {
      width: 100%;
      object-fit: contain;
      @media only screen and (max-width: 425px) {
          height: 400px;
          flex-direction: column;
        }
    }
  }

  div.right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 90px;
    width: calc(50% - 90px);
    height: 100%;

    @media only screen and (max-width: 425px) {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px 30px;
    }

    h2 {
        color: $beige;
        font-weight: 100;
        font-size: 32px;

        @media only screen and (max-width: 425px) {
          margin-top: 40px;
          font-size: 18px;
          width: 100%;
        }
    }
    
    h1 {
        color: white;
        font-weight: 200;
        font-size: 44px;
        width: 90%;

        @media only screen and (max-width: 425px) {
          font-size: 28px;
          width: 100%;
        }
    }

    div.expandable-header-container {
      @media only screen and (max-width: 425px) {
        font-size: 28px;
        width: 100%;
      }

        margin: 0px 10px;
        width: 90%;
        color: white;
        border-bottom: 1px solid rgba(101, 101, 101, .5);
        &:hover {
            cursor: pointer;
        }


        div.expanadable-header-subcontainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0px;

            
            div.expand-symbol {
                font-size: 30px;
                font-weight: 100;
            }
            h3 {
                font-size: 18px;
                font-weight: 300;
            }
        }

        p.expandable-text {
            font-size: 16px;
            line-height: 30px;
        }

    }

  }

  
}